import React from 'react';
import {Link} from "react-router-dom";
import NewsletterOne from "./NewsletterOne";
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";


const FooterFour = () => {
    return (
        <>
        </>
    )
}

export default FooterFour;
